import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PraxisGrid from "../components/praxis-grid"

function IndexPage() {
  
    return (
      <Layout>
        <Seo title="Privatpraxis für Psychotherapie München Haidhausen | Katharina Stephan" description="Wilkommen in meiner Privatpraxis für Verhaltenstherapie in München. Psychotherapie für Privatpatienten, Beihilfe & Selbstzahler. Freie Plätze auf Anfrage."/>

        <div id="hero__wrapper" className="relative">
          <div className="w-full motion-reduce:h-96 motion-safe:h-[32rem] max-h-[32rem]">
            <StaticImage 
              className="w-full h-full" 
              src="../assets/images/psychotherapie-haidhausen-raum-1.jpg" 
              alt="Heller freundlicher Praxisraum für Psychotherapie" 
            />
            <div id="hero__overlay" className="absolute top-0 flex flex-wrap items-center w-full h-full place-items-center">
              <div className="w-full text-center text-white opacity-0 hero__title">
                <h1 className="lg:text-4xl">Privatpraxis für <br/>Psychotherapie</h1>
                <div>M. Sc. Psych. Katharina Stephan</div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 mb-4 md:mb-16 md:p-12 generic-page">
          <div className="mx-auto mb-4 md:mb-6 max-w-prose">
            <h2 className="text-center">Herzlich Willkommen <br className="hidden sm:block"/>in meiner Praxis für Verhaltenstherapie in München - Haidhausen</h2>
            <p className="text-lg">Als <Link to="/katharina-stephan">Psychologische Psychotherapeutin</Link> biete ich professionelle Unterstützung bei psychischen Erkrankungen, Lebenskrisen und in Phasen der Veränderung.</p>
            <p>Das Vorgehen gestalte ich individuell auf Sie abgestimmt, um Sie zu unterstützen, Schritt für Schritt Ihre Ziele zu erreichen. Dabei ist mir in meiner Praxis für Psychotherapie eine vertrauensvolle Zusammenarbeit und der Fokus auf Ihre persönlichen Stärken besonders wichtig. Ich lade Sie ein, sich auf diesen Seiten über mein Therapieangebot zu informieren und freue mich auf Ihre <Link to="/kontakt">Kontaktaufnahme</Link>.</p>
          </div>

          <PraxisGrid />

          <div className="mx-auto mb-4 max-w-prose">
            <p>Die ruhig gelegene Praxis für Psychotherapie befindet sich in München - Haidhausen zwischen Ostbahnhof und Rosenheimer Platz in wunderschön gestalteten Altbauräumen.</p>
            <p>Als Verhaltenstherapeutin biete ich Psychotherapie, psychologische Beratung, Coaching, Paarberatung & Paartherapie für Erwachsene an. Für das Erreichen einer langfristig gesunden Psyche ergänze ich das wissenschaftlich fundierte Verfahren der kognitiven Verhaltenstherapie durch Techniken aus der Achtsamkeit und der emotionsfokussierten Therapie.</p>
            <p>Je nach Beschwerdelage bilden auch die Schematherapie, die dialektisch-behaviorale Therapie und die Traumatherapie wichtige Bausteine meiner psychotherapeutischen Arbeit. Dabei biete ich Psychotherapie in deutscher und englischer Sprache an.</p>
            <p>Für nähere Informationen zum vorliegenden Hygienekonzept in meiner Praxis für Psychotherapie in München Haidhausen oder zum Angebot von Online-Videositzungen <Link to="/kontakt">sprechen Sie mich gerne an</Link>.</p>
          </div>
          
        </div>

      </Layout>
    );
  }
  
  export default IndexPage;