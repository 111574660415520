import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Cv() {
  return (
    <div className="grid min-h-screen mb-6 place-items-center">
      <div className="grid max-w-5xl gap-4 p-4 sm:grid-cols-2 xs:p-8 md:grid-cols-4 lg:gap-6">
        <StaticImage
          className="xs:h-auto xs:square md:col-start-3"
          src="../assets/images/psychotherapeutin-katharina-stephan-1.jpg"
          alt=""
        />
        <StaticImage
          className="xs:h-auto xs:square"
          src="../assets/images/therapieraum-stephan-trockenblumen.jpg"
          alt=""
        />
        <StaticImage
          className="xs:h-auto xs:square"
          src="../assets/images/therapieraum-stephan-weit.jpg"
          alt=""
        />
        <StaticImage
          className="xs:h-auto xs:square md:col-start-2"
          src="../assets/images/therapieraum-stephan-buecher.jpg"
          alt=""
        />
        <StaticImage
          className="xs:h-auto xs:square"
          src="../assets/images/therapieraum-stephan-schreibtisch-detail.jpg"
          alt=""
        />
        <StaticImage
          className="xs:h-auto xs:square"
          src="../assets/images/therapieraum-stephan-sessel.jpg"
          alt=""
        />
        <StaticImage
          className="xs:h-auto xs:square"
          src="../assets/images/therapieraum-stephan-pflanze.jpg"
          alt=""
        />
        <StaticImage
          className="!hidden xs:h-auto xs:square sm:!inline-block"
          src="../assets/images/therapieraum-stephan-pflanze-wand.jpg"
          alt=""
        />
      </div>
    </div>
  )
}
